import ITTIModel from "@/ittijs/ITTIModel";

export default class extends ITTIModel {
	constructor() {
		super('Tickets');
	}
	getAvailableStatuses(id){
		return this.fetch('getAvailableStatuses', {id});
	}
	updateStatus(id, status, data) {
		return this.fetch('updateStatus', {id, status}, data);
	}
	getNextRefNum(id, status) {
		return this.fetch('getNextRefNum', {id, status});
	}
	addRefNumOther(statusId, refNum){
		return this.fetch('addRefNumOther', {id: statusId, ref_num_other: refNum}, {});
	}
}
