<template>
	<div class="d-flex">
		<div>
			<edit-column
				style="width: 700px"
				ref="editForm"
				v-bind="$attrs"
				v-on="$listeners"
				:fields="[
					'utility_code',
					'imot_id',
					'station_id',
					'other_parties',
					'additional_parties',
					'is_confidential',
					'doc_description',
					'notes',
					'legal_case_num',
					'deadline',
					'is_izr_delay',
					'is_izr_processed',
				]"
				:left-col-width="150"
				:disabled-fields="disabledFields"
				:data.sync="formData"
			>
				<template #field.imot_id="{row,disabled}">
					<edit-input-wrapper name="imot_id">
						<imot-select outlined dense clearable hide-details="auto" v-model="row.imot_id" :disabled="disabled" />
					</edit-input-wrapper>
				</template>
				<template #field.station_id="{row,disabled}">
					<edit-input-wrapper name="station_id">
						<station-select outlined dense clearable hide-details="auto" v-model="row.station_id" :disabled="disabled" />
					</edit-input-wrapper>
				</template>
				<template #field.doc_description="{row,disabled}">
					<edit-input-wrapper name="doc_description">
						<v-combobox :items="TicketDocumentTypes" outlined dense clearable hide-details="auto" v-model="row.doc_description" :disabled="disabled" />
					</edit-input-wrapper>
				</template>
			</edit-column>
		</div>
		<v-divider vertical class="mx-0" />
		<div style="width: 700px;" class="mx-4">
			<status-history v-if="formData?.status_history?.length > 0" :items="formData?.status_history" @reload="onReady" />
			<data-loader :watch="{id: $attrs.id, status: formData?.current_status}" :callback="()=>imodel.getAvailableStatuses($attrs.id)">
				<template #loading><v-progress-linear indeterminate /></template>
				<template #default="{data}">
					<v-sheet rounded color="grey lighten-3" class="px-4 py-2" v-if="data?.length > 0">
						<new-status-popup ref="newStatusPopup" :ticket-id="$attrs.id" @ready="onReady" />
						<v-btn
							v-for="item in data"
							:key="item.value"
							:color="TicketStatusMap.get(item.value)?.color"
							small
							class="ma-1 white--text"
							@click="$refs.newStatusPopup.open(item.value)"
						>
							<v-icon left>{{ TicketStatusMap.get(item.value)?.icon }}</v-icon>
							{{ TicketStatusMap.get(item.value)?.label }}
						</v-btn>
					</v-sheet>
				</template>
			</data-loader>
		</div>
	</div>
</template>

<script>
import EditColumn from "@/ittijs/EditColumn.vue";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import StationSelect from "@/views/stations/StationSelect.vue";
import ImotSelect from "@/views/imot/ImotSelect.vue";
import EditInputWrapper from "@/ittijs/Inputs/EditInputWrapper.vue";
import StatusHistory from "@/views/tickets/StatusHistory.vue";
import DataLoader from "@/ittijs/components/DataLoader.vue";
import IModelMixin from "@/ittijs/IModelMixin";
import {TicketDocumentTypes, TicketStatusMap} from "@/ittijs/Nomenclatures";
import NewStatusPopup from "@/views/tickets/NewStatusPopup.vue";
import {RolesEnum} from "@/ittijs/Authorization";

export default {
	components: {NewStatusPopup, DataLoader, StatusHistory, EditInputWrapper, ImotSelect, StationSelect, EditColumn},
	mixins: [SelectedUtilityMixin,IModelMixin],
	inject: ['authorization','getUserProfile'],
	data(){
		return {
			formData: null,
		}
	},
	computed  : {
		TicketDocumentTypes() {
			return TicketDocumentTypes
		},
		TicketStatusMap(){
			return TicketStatusMap
		},
		disabledFields(){
			if(this.authorization.hasRole(RolesEnum.TICKET_ADMIN)){
				return [];
			}

			const fields = [
				'utility_code',
				'imot_id',
				'station_id',
				'other_parties',
				'additional_parties',
				'doc_description',
				'legal_case_num',
				'deadline',
				'is_confidential',
			];

			const userProfile = this.getUserProfile();
			if(userProfile.ticket_role !== 'tech'){
				fields.push('is_izr_delay');
				fields.push('is_izr_processed');
			}
			return fields;
		}
	},
	methods: {
		onReady(){
			this.$refs.editForm.edit();
		},
	},
}
</script>